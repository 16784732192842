import { useAppState } from 'AppContextProvider'
import { CustomPackage, PaymentColorPackage } from 'types/onboardingTypes'

import PackageSelection from './PackageSelection'
import PackageSkeleton from './PackageSelectionSkeleton'

interface PackagesProps {
  onPackageConfirm: () => void
  selectedPackageId: string | null
  packages?: CustomPackage[]
  onPackageChanged: (id: string) => void
  discountTimeLeft: string | null
  withDiscountTimer: boolean
  colorPackage: PaymentColorPackage
}

const Packages = ({
  onPackageConfirm,
  selectedPackageId,
  onPackageChanged,
  discountTimeLeft,
  withDiscountTimer,
  colorPackage
}: PackagesProps) => {
  const { appState } = useAppState()
  if (!appState.stripePrices || !selectedPackageId) {
    return <PackageSkeleton />
  }

  return <PackageSelection
    onPackageConfirm={onPackageConfirm}
    selectedPackageId={selectedPackageId}
    onPackageChanged={onPackageChanged}
    discountTimeLeft={discountTimeLeft}
    withDiscountTimer={withDiscountTimer}
    colorPackage={colorPackage}
  />
}

export default Packages
