
import { getUserLocaleForDateFns } from 'Lib'
import { DateTime } from 'luxon'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { useFeatureValue } from '@growthbook/growthbook-react'

interface DisclaimerProps {
  interval: string
  numberOfPeriods: number
  showDiscountDisclaimer: boolean
  price: string
  trialPrice: string | null
  discountedPrice: string | null
}

const DisclaimerText = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  text-align: center;
  color: ${({ theme }) => theme.colors.lightText};
  padding-top: ${({ theme }) => theme.spacing.small};
`

const getFormattedDateForDisclaimer = () => DateTime.now()
  .plus({ days: 7 })
  .setLocale(getUserLocaleForDateFns()?.code || DateTime.local().locale)
  .toLocaleString(DateTime.DATE_MED)
  .replace(/ /g, '\u00A0')

const Disclaimer = ({ interval, numberOfPeriods, showDiscountDisclaimer, price, trialPrice, discountedPrice }: DisclaimerProps) => {
  const { t } = useTranslation()
  const showSameDisclaimerTexts = (useFeatureValue('payment-screen-show-same-disclaimer-texts', false))

  const periodString =
    interval === 'year'
      ? t('year')
      : interval === 'month'
        ? numberOfPeriods === 1
          ? t('month_one')
          : t('month_other')
        : numberOfPeriods === 1
          ? t('week_one')
          : t('week_other')

  const disclaimerOne = showDiscountDisclaimer
    ? t('package.disclaimerDiscount',
      {
        price: trialPrice ?? price,
        discountedPrice: discountedPrice ?? price,
        interpolation: { escapeValue: false }
      })
    : t('package.disclaimer', { price, interpolation: { escapeValue: false } })

  const disclaimerTwo = t(
    interval === 'week' ? 'modal.disclaimerTrial'
      : interval === 'year' ? 'modal.disclaimerYear'
        : numberOfPeriods === 1 ? 'modal.disclaimerOneMonth'
          : 'modal.disclaimer',
    {
      price: trialPrice ?? price,
      initialPrice: discountedPrice ?? price,
      period: periodString,
      interval: numberOfPeriods,
      interpolation: { escapeValue: false },
      endDate: getFormattedDateForDisclaimer()
    })

  return <DisclaimerText>{showSameDisclaimerTexts ? disclaimerOne : disclaimerTwo}</DisclaimerText>
}

export default Disclaimer
