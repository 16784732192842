import { useAppState } from 'AppContextProvider'
import { images } from 'images'
import { formatPriceString, getDiscountPercentage, mapTagIdToBadgePackageTagId } from 'Lib'
import { AnalyticEvents, ZERO_DECIMAL_CURRENCIES } from 'Lib/Constants'
import { useBadgePackageTagId, usePaymentScreenColorScheme } from 'Lib/hooks'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineClock } from 'react-icons/hi'
import styled from 'styled-components'
import {
  BadgePackageTagId, CustomPackage, PackageTagId, PaymentColorPackage, PaymentColorScheme
} from 'types/onboardingTypes'

import { useFeatureValue } from '@growthbook/growthbook-react'

import Button from '../Button'

const PackagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.spacing.medium};
`

const PackageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 0px ${({ theme }) => theme.spacing.small};
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
`

const PackageWrapperBorder = styled.div<{ color: string, selected: boolean }>`
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing.small};
  border: 2px solid ${({ selected, theme, color }) => (selected ? (color) : theme.colors.lightGray)};
  max-width: 400px;
  transition: all 0.2s ease-in-out;
  box-shadow: ${({ selected }) => (selected ? '0px 4px 8px rgba(0, 0, 0, 0.2)' : 'none')};
  transform: ${({ selected }) => (selected ? 'scale(1.1)' : 'scale(1)')};
  @media(max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    transform: ${({ selected }) => (selected ? 'scale(1.05)' : 'scale(0.97)')};
  }
`

const PackageWrapper = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.small};
  position: relative;
  cursor: pointer;

  &:hover {
    border-color: ${({ selected }) => (selected ? '#10b294' : '#999')};
  }
`

const Checkbox = styled.div<{ selected: boolean, color: string }>`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid ${({ selected, theme, color }) => (selected ? color : theme.colors.lightGray)};
  background-color: ${({ selected, theme, color }) => (selected ? color : theme.colors.transparent)};
  position: relative;
  margin-right: ${({ theme }) => theme.spacing.small};

  &:after {
    content: '';
    display: ${({ selected }) => (selected ? 'block' : 'none')};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7px;
    height: 7px;
    border: 4px solid ${({ selected, theme, color }) => (selected ? theme.colors.white : color)};
    background-color: ${({ selected, theme, color }) => (selected ? color : theme.colors.white)};
    border-radius: 50%;
  }
`

const PackageInformation = styled.span<{ selected: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  font-weight: bold;
  margin-right: auto;
  white-space: pre-line;
  display: flex;
  flex-direction: column;
  color: ${({ selected, theme }) => (selected ? theme.colors.text : '#8995a4')};
  flex-grow: 1;
`

const Tag = styled.div<{ color?: string, darkColor: string, onClick?: () => void }>`
  background-color: ${({ color, theme }) => color ?? theme.colors.secondary};
  color: #fff;
  font-size: 12px;
  padding: 4px 0px;
  width: 100%;
  border-radius: ${({ theme }) => theme.spacing.small + ' ' + theme.spacing.small + ' 0 0'};
  border: 2px solid ${({ color, darkColor }) => color ?? darkColor};
  max-width: 400px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  margin-left: -2px;
  margin-top: -2px;
  pointer-events: auto;
  cursor: pointer;
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const PriceIntegral = styled.span<{ selected: boolean, isLongPrice: boolean, selectedTextColor: string }>`
  font-weight: bold;
  color: ${({ selected, selectedTextColor }) => (
    selected
    ? (selectedTextColor)
    : ('#8995a4'))};
  font-size: ${({ theme }) => theme.fontSizes.xLarge};
  @media(max-width: ${({ theme }) => theme.breakpoints.mobile}) {
    font-size: ${({ theme, isLongPrice }) => isLongPrice ? theme.fontSizes.large : theme.fontSizes.xLarge};
  }
`

const PriceDecimal = styled.span<{ selected: boolean, selectedTextColor: string }>`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: bold;
  color: ${({ selected, selectedTextColor }) => (
    selected
    ? (selectedTextColor)
    : ('#8995a4'))};
`

const PriceCurrency = styled.span<{ selected: boolean, selectedTextColor: string}>`
  font-size:  ${({ theme }) => theme.fontSizes.small};
  font-weight: bold;
  margin-top: ${({ theme }) => theme.spacing.small};
  margin-left: 2px;
  color: ${({ selected, selectedTextColor, theme }) => (
    selected
    ? (selectedTextColor)
    : ('#8995a4'))};
`

const OriginalPricePerDay = styled.span<{ color: string, visible: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.small};
  font-weight: bold;
  margin-right: 5px;
  color: #8995a4;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 4px;
  text-decoration: line-through;
  text-decoration-thickness: 1px;
  text-decoration-color: ${({ color }) => (color)};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  align-self: end;
`

const TrialDisclaimer = styled.span`
  font-size:  ${({ theme }) => theme.fontSizes.xsmall};
  font-weight: bold;
  color: ${({ theme }) => theme.colors.lightText};
  background-color: ${({ theme }) => theme.colors.lightGray};
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 6px;
  padding-right: 6px;
  border-radius: 4px;
  margin-top: ${({ theme }) => theme.spacing.xsmall};
  width: fit-content;
`

const PricePerDay = styled.span<{ selected: boolean, selectedTextColor: string }>`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  margin-top: ${({ theme }) => theme.spacing.zero};
  align-self: flex-end;
  line-height: 10px;
  color: ${({ selected, selectedTextColor }) => (
    selected
    ? (selectedTextColor)
    : ('#8995a4'))};
  white-space: nowrap;
`

const PricePerDayAndDecimal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 10px;
  margin-left: 2px;

  span {
    margin: 0;
  }
`

const DaysBackGuarantee = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.medium};
  margin-top: ${({ theme }) => theme.spacing.large};
  margin-left: ${({ theme }) => theme.spacing.medium};
  font-weight: bold;
`

const Disclaimer = styled.span`
  font-size: ${({ theme }) => theme.fontSizes.xsmall};
  text-align: center;
  color: ${({ theme }) => theme.colors.lightText};
`

const LongPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 8px;
`

const ShortPriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const DiscountCountdownContainer = styled.div<{ color: string }>`
  width: 100%;
  max-width: 404px;
  margin: 0px ${({ theme }) => theme.spacing.xLarge};
  background-color: ${({ color }) => color};
  border-radius: ${({ theme }) => theme.spacing.small};
`

const DiscountGiftContainer = styled.div<{ color: string }>`
  width: 100%;
  max-width: 404px;
  align-items: flex-start;
  margin: 0px ${({ theme }) => theme.spacing.xLarge};
  background-color: ${({ color }) => color}99;
  border-radius: ${({ theme }) => theme.spacing.small};
  display: flex;
  flex-direction: row;
  align-items: center;
`

const DiscountCountdownText = styled.strong`
  padding: ${({ theme }) => theme.spacing.small} ${({ theme }) => theme.spacing.small};
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre;
`

const PricesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  justify-content: space-between;
  width: 100%;
`

const TotalOriginalPrice = styled.span<{ selected: boolean, discountAvailable: boolean }>`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ selected, theme }) => (selected ? theme.colors.text : '#8995a4')};
  text-decoration: ${({ discountAvailable }) => discountAvailable ? 'line-through' : 'none'};
  font-weight: normal
`

const TotalDiscountedPrice = styled.span<{ selected: boolean, isTotalPriceShown?: boolean}>`
  font-size: ${({ theme }) => theme.fontSizes.small};
  color: ${({ selected, theme }) => (selected ? theme.colors.text : '#8995a4')};
  margin-left:  ${({ isTotalPriceShown, theme }) => (isTotalPriceShown ? theme.spacing.small : 0)};
  font-weight: normal
`

const Icon = styled(HiOutlineClock)`
  width: 22px;
  height: 22px;
  margin-right: ${({ theme }) => theme.spacing.xsmall};
  margin-bottom: 1px;
`

const GiftIcon = styled.span`
  font-size: 48px;
  margin-right: ${({ theme }) => theme.spacing.small};
  margin-left: ${({ theme }) => theme.spacing.medium};
`

const DiscountGiftPercentage = styled.strong`
  color: #ff4f64;
  margin-right: 4px;
  margin-left: 4px;
  display: contents;
  font-size: 1.2em;
`

const LeftArrow = styled.img<{ color: string }>`
  object-fit: cover;
  align-self: center;
  margin-right: -2px;
  color: ${({ color }) => (color)};
`

const PackagePriceDescriptionWrapper = styled.div<{ selected: boolean, color: string }>`
  background-color: ${({ selected, color }) => selected ? color : '#f6f6f7'};
  display: flex;
  padding: 0 8px;
  padding-left: 0;
  margin-top: 4px;
  margin-bottom: 4px;
  border-radius: 0 8px 8px 0;
`

const PriceWithBackgroundContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const GiftTimeLeft = styled.strong`
  align-self: flex-start;
`

const GiftTimeLeftContainer = styled.div`
  margin-top: ${({ theme }) => theme.spacing.small};
  font-weight: normal;
`
const GiftTextsContainer = styled.div<{ discountTimeLeft: boolean, textColor: string}>`
  padding: ${({ theme }) => theme.spacing.medium};
  padding-left: ${({ theme, discountTimeLeft }) => discountTimeLeft ? '0' : theme.spacing.medium};
  font-weight: bold;
  color: ${({ textColor }) => textColor};
`
const SaleBadgeContainer = styled.div`
  position: absolute;
  width: 60px;
  height: 60px;
  right: 0;
  z-index: 1;
  top: -35px;
`

const SaleImage = styled.img`
  width: 100%;
  height: 100%;
`

const SaleText = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) 
  rotate(30deg);
  font-size: ${({ theme }) => theme.fontSizes.medium};
  color: white;
  font-weight: bold;
  
`

interface SaleBadgeProps {
  src: string
  discountPercentage: number
}

const SaleBadge = ({ src, discountPercentage }: SaleBadgeProps) => {
  return (
    <SaleBadgeContainer>
      <SaleImage src={src} />
      <SaleText>{discountPercentage}%</SaleText>
    </SaleBadgeContainer>
  )
}

const getPackageColor = (colorPackage: PaymentColorPackage, tagId?: PackageTagId) => {
  switch (tagId) {
    case PackageTagId.Trial:
      return colorPackage.packageColorDark
    case PackageTagId.BestValue:
    case PackageTagId.FastTrack:
      return colorPackage.packageColorLight
    case PackageTagId.MostPopular:
      return colorPackage.packageColorDark
    default:
      return colorPackage.packageColorDark
  }
}

const getSelectedPriceTagConfig = (colorScheme: PaymentColorScheme, colorPackage: PaymentColorPackage, tagId?: PackageTagId): {checkBoxColor: string, tagColor: string, imageSource: string} => {
  const tagColor = getPackageColor(colorPackage, tagId)

  if (colorScheme === 'original') {
    return { checkBoxColor: colorPackage.bannerColor, tagColor: colorPackage.selectedPriceColor, imageSource: colorPackage.selectedLeftRoundedArrow }
  }

  switch (tagColor) {
    case colorPackage.packageColorDark:
      return { checkBoxColor: tagColor, tagColor, imageSource: colorPackage.selectedLeftRoundedArrow }
    case colorPackage.packageColorLight:
      return { checkBoxColor: tagColor, tagColor, imageSource: colorPackage.selectedLeftRoundedArrowLight }
    default:
    {
      logError(AnalyticEvents.WEB_SELECTED_TAG_CONFIG_ERROR, new Error('unexpected  tagColor'), { tagColor }, true)
      return { checkBoxColor: tagColor, tagColor, imageSource: colorPackage.selectedLeftRoundedArrow }
    }
  }
}

export const getPricePerDayToFormat = (stripePrices: CustomPackage[], pricePerDay: number, currency: string, discountedPricePerDay?: number) => {
  const isZeroDecimalCurrency = ZERO_DECIMAL_CURRENCIES.includes(currency.toUpperCase())
  const containsLongPrice = stripePrices.some((p) => p.pricePerDay >= 100)
  const price = discountedPricePerDay ?? pricePerDay

  if (isZeroDecimalCurrency) {
    return Math.round(price * 100)
  }
  if (containsLongPrice) {
    return Math.round(price)
  }
  return price
}

interface PackageProps {
  id: string
  name: string
  price: number
  pricePerDay: number
  discountedPricePerDay?: number
  discountedPrice?: number
  interval: string
  selected: boolean
  currency: string
  tag?: string
  tagId?: PackageTagId
  color?: string
  onClick: (id: string) => void
  discountPercentage: number | null
  discountTimeLeft: string | null
  withDiscountTimer: boolean
  colorPackage: PaymentColorPackage
}

const Package = ({
  id,
  name,
  pricePerDay,
  price,
  discountedPricePerDay,
  discountedPrice,
  selected,
  tag,
  tagId,
  currency,
  color,
  onClick,
  discountPercentage,
  discountTimeLeft,
  withDiscountTimer,
  colorPackage
}: PackageProps) => {
  const { t } = useTranslation()
  const { appState } = useAppState()
  const [arrowHeight, setArrowHeight] = useState(48)
  const [windowWidth, setWindowWidth] = useState(0)
  const packagePricesRef = useRef<any>(null)

  const badgePackageTagId = useBadgePackageTagId()
  const showTrialOriginalPricePerDay = useFeatureValue('payment-screen-show-trial-original-price-per-day', true)
  const showTrialOriginalPrice = useFeatureValue('payment-screen-show-trial-original-price', true)

  let shouldDisplayOriginalPricePerDay: boolean = true
  let badgeTagId
  if (badgePackageTagId !== BadgePackageTagId.None) {
    badgeTagId = mapTagIdToBadgePackageTagId(badgePackageTagId)
  }

  const shouldDisplaySaleBadge =
      tagId === badgeTagId &&
        !!discountPercentage &&
        (withDiscountTimer ? discountTimeLeft : true)

  const shouldDisplayOriginalPrice = (withDiscountTimer ? !discountTimeLeft : false) || (tagId === PackageTagId.Trial ? showTrialOriginalPrice : true)

  // If the package is set to have a sale badge and if the sale badge is enabled, don't show the original price per day
  if (shouldDisplaySaleBadge) {
    shouldDisplayOriginalPricePerDay = false
  } else if (tagId === PackageTagId.Trial) {
    shouldDisplayOriginalPricePerDay = showTrialOriginalPricePerDay
  }
  useEffect(() => {
    setArrowHeight(packagePricesRef.current?.clientHeight)
  }, [windowWidth])

  useEffect(() => {
    const listener = () => setWindowWidth(window.innerWidth)
    window.addEventListener('resize', listener)

    return () => {
      window.removeEventListener('resize', listener)
    }
  }, [])

  // make sure all packages are formatted the same way if one of the prices goes above 100
  const containsLongPrice = appState.stripePrices!.some((p) => p.pricePerDay >= 100)

  const isZeroDecimalCurrency = ZERO_DECIMAL_CURRENCIES.includes(currency.toUpperCase())
  const priceToFormat = getPricePerDayToFormat(appState.stripePrices!, pricePerDay, currency, discountedPricePerDay)

  const priceIntegral = (priceToFormat).toFixed(2).split('.')[0]
  const priceDecimal = isZeroDecimalCurrency ? null : (priceToFormat).toFixed(2).split('.')[1]
  const formattedOriginalPricePerDay = formatPriceString({ price: pricePerDay, currency, withFractionDigits: !containsLongPrice, currencyDisplay: 'symbol' })
  const formattedOriginalPrice = formatPriceString({ price, currency, withFractionDigits: !containsLongPrice, currencyDisplay: 'symbol' })
  const formattedDiscountedPrice = discountedPrice ? formatPriceString({ price: discountedPrice, currency, withFractionDigits: !containsLongPrice, currencyDisplay: 'symbol' }) : null
  const currencySymbol = formattedOriginalPricePerDay.replace(/[\d,.]/g, '').trim()

  const isPackageTrial = tagId === PackageTagId.Trial
  const selectedPriceTagConfig = getSelectedPriceTagConfig(usePaymentScreenColorScheme(), colorPackage, tagId)
  const selectedLeftRoundedArrow = selectedPriceTagConfig.imageSource

  const renderPriceContainer = () => {
    if (isZeroDecimalCurrency || containsLongPrice) {
      return (
        <PriceContainer>
          {discountedPricePerDay && (
            <OriginalPricePerDay color={colorPackage.packageColorDark} visible={shouldDisplayOriginalPricePerDay}>{formattedOriginalPricePerDay}</OriginalPricePerDay>
          )}
          <PriceWithBackgroundContainer>
            <LeftArrow height={arrowHeight} src={selected ? selectedLeftRoundedArrow : images.LeftRoundedArrow} color={selectedPriceTagConfig.tagColor} />
            <PackagePriceDescriptionWrapper selected={selected} ref={packagePricesRef} color={selectedPriceTagConfig.tagColor}>
              <PriceCurrency selected={selected} selectedTextColor={colorPackage.selectedPriceTextColor}>{currencySymbol}</PriceCurrency>
              <LongPriceContainer>
                <PriceIntegral selected={selected} isLongPrice={true} selectedTextColor={colorPackage.selectedPriceTextColor}>{priceIntegral}</PriceIntegral>
                <PricePerDay selected={selected} selectedTextColor={colorPackage.selectedPriceTextColor} >{t('package.perDay')}</PricePerDay>
              </LongPriceContainer>
            </PackagePriceDescriptionWrapper>
          </PriceWithBackgroundContainer>
        </PriceContainer>
      )
    }
    return (
      <PriceContainer>
        {discountedPricePerDay && (
          <OriginalPricePerDay visible={shouldDisplayOriginalPricePerDay} color={colorPackage.packageColorDark}>{formattedOriginalPricePerDay}</OriginalPricePerDay>
        )}
        <PriceWithBackgroundContainer>
          <LeftArrow height={arrowHeight} src={selected ? selectedLeftRoundedArrow : images.LeftRoundedArrow} color={selectedPriceTagConfig.tagColor} />
          <PackagePriceDescriptionWrapper selected={selected} ref={packagePricesRef} color={selectedPriceTagConfig.tagColor}>
            <PriceCurrency selected={selected} selectedTextColor={colorPackage.selectedPriceTextColor}>{currencySymbol}</PriceCurrency>
            <ShortPriceContainer>
              <PriceIntegral isLongPrice={false} selected={selected} selectedTextColor={colorPackage.selectedPriceTextColor} >{priceIntegral}</PriceIntegral>
            </ShortPriceContainer>
            <PricePerDayAndDecimal>
              <PriceDecimal selected={selected} selectedTextColor={colorPackage.selectedPriceTextColor}>{priceDecimal}</PriceDecimal>
              <PricePerDay selected={selected} selectedTextColor={colorPackage.selectedPriceTextColor} >{t('package.perDay')}</PricePerDay>
            </PricePerDayAndDecimal>
          </PackagePriceDescriptionWrapper>
        </PriceWithBackgroundContainer>
      </PriceContainer>
    )
  }

  return (
    <PackageContainer>
      <PackageWrapperBorder selected={selected} color={getPackageColor(colorPackage, tagId)!}>
        {tag && <Tag color={color} darkColor={colorPackage.packageColorDark} onClick={() => onClick(id)}>{tag}</Tag>}
        <PackageWrapper selected={selected} onClick={() => onClick(id)}>
          { shouldDisplaySaleBadge && <SaleBadge src={ images.ic_bg_sale } discountPercentage={ discountPercentage }/> }
          <Checkbox selected={selected} color={selectedPriceTagConfig.checkBoxColor} />
          <PackageInformation selected={selected} >
            {name}
            {isPackageTrial && (
              <TrialDisclaimer>{t('package.trialOneMonth')}</TrialDisclaimer>
            )}
            <PricesContainer>
              <div>
                {shouldDisplayOriginalPrice && <TotalOriginalPrice discountAvailable={!!discountedPrice} selected={selected}>{formattedOriginalPrice}</TotalOriginalPrice>}
                {formattedDiscountedPrice && (
                  <TotalDiscountedPrice selected={selected} isTotalPriceShown={shouldDisplayOriginalPrice}>{formattedDiscountedPrice}</TotalDiscountedPrice>
                )}
              </div>
            </PricesContainer>
          </PackageInformation>
          {renderPriceContainer()}
        </PackageWrapper>
      </PackageWrapperBorder>
    </PackageContainer >
  )
}

interface PackageSelectionProps {
  onPackageConfirm: () => void
  selectedPackageId: string
  onPackageChanged: (id: string) => void
  colorPackage: PaymentColorPackage
  discountTimeLeft: string | null
  withDiscountTimer: boolean
}

const PackageSelection = ({ onPackageConfirm, selectedPackageId, onPackageChanged: handlePackageClick, colorPackage, discountTimeLeft, withDiscountTimer }: PackageSelectionProps) => {
  const { t } = useTranslation()
  const { appState } = useAppState()
  const selectedPackage = appState.stripePrices!.find((pkg) => pkg.id === selectedPackageId)!

  const packages = appState.stripePrices

  const discountedPriceString = selectedPackage.discountedPrice ? formatPriceString({ price: selectedPackage.discountedPrice, currency: selectedPackage.currency, withFractionDigits: true }) : null
  const discountPercentage = getDiscountPercentage(selectedPackage)

  const mainPriceString = formatPriceString({ price: selectedPackage.price, currency: selectedPackage.currency, withFractionDigits: true })
  let trialMainPlanPriceString = null
  if (selectedPackage.tagId === PackageTagId.Trial) {
    const recurringPackage = appState.stripePrices!.find((price) => price.interval === 'month' && price.numberOfPeriods === 1)
    trialMainPlanPriceString = formatPriceString({ price: recurringPackage!.price, currency: recurringPackage!.currency, withFractionDigits: true })
  }

  const disclaimerText = ((trialMainPlanPriceString) || discountedPriceString)
    ? t('package.disclaimerDiscount', { price: trialMainPlanPriceString ?? mainPriceString, discountedPrice: discountedPriceString ?? mainPriceString, interpolation: { escapeValue: false } })
    : t('package.disclaimer', { price: mainPriceString, interpolation: { escapeValue: false } })

  return (
    <>
      <PackagesWrapper>
        {packages?.map((pkg) => (
          <Package
            key={pkg.id}
            color={getPackageColor(colorPackage, pkg.tagId)}
            {...pkg}
            selected={pkg.id === selectedPackage.id}
            onClick={handlePackageClick}
            discountPercentage={discountPercentage}
            discountTimeLeft={discountTimeLeft}
            withDiscountTimer={withDiscountTimer}
            colorPackage={colorPackage}
          />
        ))}
      </PackagesWrapper>
      <DaysBackGuarantee>{t('package.moneyBackGuarantee')}</DaysBackGuarantee>
      <Button maxWidth='400px' margin='30px' enablePulse primaryColor={colorPackage.buttonColor} onClick={() => {
        return onPackageConfirm()
      }}>
        {t('package.confirmButton')}
      </Button>

      <Disclaimer>
        {disclaimerText}
      </Disclaimer>
    </>
  )
}

export default PackageSelection
