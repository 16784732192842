import { useAppState } from 'AppContextProvider'
import BottomSliderModal from 'Components/BottomSliderModal'
import { PaypalButton } from 'Components/PaypalButton.component'
import { showToast } from 'Components/Toast'
import { formatPriceString, getUserLanguage } from 'Lib'
import { AnalyticEvents } from 'Lib/Constants'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { theme } from 'styles/theme'
import { CustomPackage, PackageTagId } from 'types/onboardingTypes'

import { Elements, useStripe } from '@stripe/react-stripe-js'
import { StripeElementLocale, StripeElementsOptions, StripeError } from '@stripe/stripe-js'

import CheckoutForm from './CheckoutForm'
import PricePerDay from './PricePerDay'
import TotalPrice from './TotalPrice'

export const PAYPAL_FIRST_BUTTON_COUNTRIES = ['DE', 'AT', 'CH']

const Title = styled.h2`
  font-size: ${props => props.theme.fontSizes.medium};
  font-weight: bold;
  text-align: center;
  margin: ${props => props.theme.spacing.large}
    ${props => props.theme.spacing.zero}
    ${props => props.theme.spacing.medium};
  color: ${({ theme }) => theme.colors.text};
`

const Content = styled.div`
  margin-top: ${props => props.theme.spacing.medium};
`
const SmallSpacer = styled.div`
height: ${props => props.theme.spacing.small};
`

const STRIPE_ELEMENTS_APPEARANCE: StripeElementsOptions['appearance'] = {
  theme: 'flat',
  variables: {
    fontFamily: 'Fira Sans, sans-serif',
    colorPrimary: theme.colors.primary,
    colorPrimaryText: theme.colors.text,
    colorBackgroundText: theme.colors.lightText,
    fontSizeBase: theme.fontSizes.medium,
    fontLineHeight: '1.1',
    spacingAccordionItem: theme.spacing.medium,
  },
  rules: {
    '.AccordionItem': {
      marginBottom: theme.spacing.xsmall
    },
    '.Tab': {
      border: `1px solid ${theme.colors.lightGray}`,
      backgroundColor: 'white'
    },
    '.Tab--selected': {
      border: `3px solid ${theme.colors.primary}`,
      backgroundColor: 'white'
    },
    '.TabIcon--selected': {
      fill: theme.colors.primary
    },
    '.TabLabel--selected': {
      color: theme.colors.primary
    },
    '.Text--redirect': {
      fontSize: '0px'
    },
    '.Block': {
      border: 'none',
      boxShadow: 'none',
      backgroundColor: theme.colors.transparent,
      paddingBottom: '0px'
    },
    '.Label': {
      fontSize: '0px'
    }
  }
}

interface BuyPackageModalProps {
  onClose: () => void
  selectedPackageId: string
  email: string
  externalStripeError?: StripeError
}

const BuyPackageModal = ({ onClose, selectedPackageId, email, externalStripeError }: BuyPackageModalProps) => {
  const { t } = useTranslation()
  const {
    appState: {
      paypalNativeFallbackEnabled,
      paypalElementsEnabled,
      stripePrices
    }
  } = useAppState()
  const sliderModalRef = useRef()
  const stripe = useStripe()

  const selectedPackage = stripePrices!.find((pkg: CustomPackage) => pkg.id === selectedPackageId)!

  const country = localStorage.getItem('countryCode') ?? ''

  const language = getUserLanguage()

  const { discountedPriceInCents, priceInCents, currency, price, tagId, discountedPrice, discountedPricePerDay, pricePerDay, } = selectedPackage

  const stripeAmount = discountedPriceInCents ?? priceInCents
  const stripeCurrency = currency.toLowerCase()

  function sliderModalAutoScrollHandler (): void {
    setTimeout(() => (sliderModalRef.current as any).scrollToBottom(), 300)
  }

  const mainPriceString = formatPriceString({ price, currency, withFractionDigits: true })
  let recurringPackage
  let trialMainPlanPriceString = null
  if (tagId === PackageTagId.Trial) {
    recurringPackage = stripePrices!.find((price) => price.interval === 'month' && price.numberOfPeriods === 1)
    trialMainPlanPriceString = formatPriceString({ price: recurringPackage!.price, currency: recurringPackage!.currency, withFractionDigits: true })
  }

  const discountedPriceString = discountedPrice ? formatPriceString({ price: discountedPrice, currency, withFractionDigits: true }) : null

  const onError = (error: any, channel: string, planId: string): void => {
    logEvent(AnalyticEvents.WEB_PURCHASE_EXCEPTION, { channel, subscriptionModel: planId, exception: error })

    // We don't want to show an error message if the user closes the PayPal window
    if (channel === 'paypal' && error?.message === '"Window is closed, can not determine type"') {
      return
    }

    showToast('error', t('errors.unknownError', { ns: 'translation' }))
  }

  const stripeElementsOptions: StripeElementsOptions = {
    appearance: STRIPE_ELEMENTS_APPEARANCE,
    amount: stripeAmount,
    currency: stripeCurrency,
    // disable the default elements loader, to allow to use a custom loader
    loader: 'never',
    mode: 'subscription',
    locale: (language as StripeElementLocale),
    paymentMethodTypes: paypalElementsEnabled === true ? ['card', 'paypal'] : ['card'],
    fonts: [{
      // !!! maybe self hosted fonts can be used here
      cssSrc: 'https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap'
    }]
  }

  const paypalAsFirstButton = PAYPAL_FIRST_BUTTON_COUNTRIES.includes(country.toUpperCase())

  return <BottomSliderModal ref={sliderModalRef} onClose={onClose}>
    <Content>
      <PricePerDay price={formatPriceString({ price: discountedPricePerDay ?? pricePerDay, currency, withFractionDigits: pricePerDay < 100 })} />
      <TotalPrice
        priceString={mainPriceString}
        discountedPriceString={discountedPriceString}
        selectedPackage={selectedPackage}
        recurringPackage={recurringPackage}
      />
    </Content>
    <Title>{t('modal.chose_a_payment_method')}</Title>
    {paypalNativeFallbackEnabled &&
        <>
          <PaypalButton
            onError={(error) => onError(error, 'paypal', selectedPackage.id)}
            selectedPackage={selectedPackage}
          />
          <SmallSpacer />
        </>}
    <div id='checkout'>
      <Elements options={stripeElementsOptions} stripe={stripe}>
        <CheckoutForm
          prices={{ mainPrice: mainPriceString, discountPrice: discountedPriceString, trialPrice: trialMainPlanPriceString }}
          selectedPackage={selectedPackage}
          paypalAsFirstButton={paypalAsFirstButton}
          email={email}
          onScrollDownRequest={sliderModalAutoScrollHandler}
          formLayout='tabs'
          externalStripeError={externalStripeError}
        />
      </Elements>
    </div>
  </BottomSliderModal>
}

export default BuyPackageModal
