import { combineReducers } from 'redux'
import { AppReducer, AppState } from './AppRedux'
import { createBrowserHistory } from 'history'
import { connectRouter } from 'connected-react-router'
import { StartupReducer, StartupState } from 'Reducers/StartupRedux'
import { LoginReducer, LoginState } from 'Reducers/LoginRedux'

export interface IRootState {
  App: AppState
  startup: StartupState
  login: LoginState
  router: any
}

export const history = createBrowserHistory()

export const rootReducer = combineReducers<IRootState>({
  router: connectRouter(history),
  App: AppReducer,
  startup: StartupReducer,
  login: LoginReducer
})
