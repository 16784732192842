//    _____ ____  _   _  _____ _______       _   _ _______ _____
//   / ____/ __ \| \ | |/ ____|__   __|/\   | \ | |__   __/ ____|
//  | |   | |  | |  \| | (___    | |  /  \  |  \| |  | | | (___
//  | |   | |  | | . ` |\___ \   | | / /\ \ | . ` |  | |  \___ \
//  | |___| |__| | |\  |____) |  | |/ ____ \| |\  |  | |  ____) |
//   \_____\____/|_| \_|_____/   |_/_/    \_\_| \_|  |_| |_____/

import { IFirebaseService, IGTagService, ITwitterService } from 'Services'
import { CustomPackage } from 'types/onboardingTypes'
import * as Sentry from '@sentry/react'
import { AnalyticEvents } from './Constants'
import { getLocalId } from 'Lib/AppUtils'

/**
 * This events also need to be sent to other analytic services
 * e.g. Facebook payment tracking, Appsflyer conversion, etc.
 */
const THIRD_PARTY_SUPPORTED_EVENTS = [
  AnalyticEvents.START_CHECKOUT,
  AnalyticEvents.PURCHASE_SUCCESS,
  AnalyticEvents.SIGN_UP_SUCCESS
]

function logEventToThirdParty(firebaseService: IFirebaseService, facebookService: any, gTagService: IGTagService, twitterService: ITwitterService | null, eventName: AnalyticEvents, params: any) { // Pinterest service is removed temporarily.
  if (eventName === AnalyticEvents.PURCHASE_SUCCESS) {
    // Facebook event
    facebookService.track('Purchase',
      {
        currency: params.currency,
        value: params.value,
        fbc: params.fbc,
        fbp: params.fbp
      })

    // Appsflyer event
    AF?.('pba', 'event', {
      eventType: 'EVENT',
      eventName: 'purchase',
      eventRevenue: params.value,
      eventRevenueCurrency: params.currency,
      eventValue: {
        sku: params.pricingId,
        unit_price: params.value,
        currency: params.currency
      }
    })

    // TikTok Event
    ttq?.track('CompletePayment', {
      contents: [
        {
          content_id: params.pricingId // string. ID of the product. Example: "1077218".
        }
      ],
      value: params.value, // number. Value of the order or items sold. Example: 100.
      currency: params.currency // string. The 4217 currency code. Example: "USD".
    })

    gTagService.logPurchase({
      affiliation: params.affiliation,
      currency: params.currency,
      value: params.value,
      items: [params.pricingId],
      transaction_id: params.purchaseId
    })

    twitterService?.logPurchase({
      conversion_id: params.purchaseId,
      currency: params.currency,
      value: params.value,
      description: params.affiliation,
      status: 'completed',
      twclid: params.twclid
    })
    /*
    pinterestService?.logPurchase({
      event_id: params.purchaseId,
      order_id: params.purchaseId,
      currency: params.currency,
      value: params.value,
      product_id: params.pricingId
    })
    */
  }

  if (eventName === AnalyticEvents.START_CHECKOUT) {
    const checkoutParams: { channel: string } & CustomPackage = { ...params }
    firebaseService.logEvent('begin_checkout', {
      currency: checkoutParams.currency,
      value: checkoutParams.price,
      items: [checkoutParams.id]
    })

    facebookService.track('InitiateCheckout', {
      fbc: params.fbc,
      fbp: params.fbp
    })

    ttq?.track('InitiateCheckout', {
      contents: [
        {
          content_id: params.pricingId, // string. ID of the product. Example: "1077218".
          content_name: params.pricingId // string. The name of the page or product. Example: "shirt".
        }
      ],
      currency: checkoutParams.currency,
      value: checkoutParams.price,
    })
  }

  if (eventName === AnalyticEvents.SIGN_UP_SUCCESS) {
    facebookService.track('CompleteRegistration', {
      fbc: params.fbc,
      fbp: params.fbp
    })

    AF?.('pba', 'event', {
      eventType: 'EVENT',
      eventName: 'signup'
    })

    ttq?.track('CompleteRegistration', {
      contents: [
        {
          content_id: params.pricingId, // string. ID of the product. Example: "1077218".
          content_name: params.pricingId // string. The name of the page or product. Example: "shirt".
        }
      ]
    })
  }
}

/**
 * Global logEvent function
 */
export function createAnalytics(firebaseServicePromise: Promise<IFirebaseService>, facebookService: any, gTagService: IGTagService, twitterService: ITwitterService | null) { // Pinterest service is removed temporarily.
  window.logError = async (event: string, error: Error | string | null = null, params?: Record<string, any>, logToSentry: boolean = false) => {
    // Create error object if error is null or a string
    if (error == null) {
      error = new Error(event)
    } else if (typeof error === 'string') {
      error = new Error(error)
    }

    if (__DEV__) {
      console.error('logError: ', event, error ?? '', params ?? '')
    }

    if (!__DEV__) {
      try {
        gTagService.logEvent(event, {
          localId: getLocalId(),
          ...params
        })

        if (logToSentry) {
          Sentry.captureException(error, {
            level: 'error',
            extra: {
              event
            }
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
  window.logEvent = async (eventName: AnalyticEvents, params?: Record<string, any>) => {
    if (__DEV__) {
      console.log('logEvent: ', eventName, params ?? '')
    }

    // firebaseService.logEvent(eventName, params) // gtag is already logging for firebase
    gTagService.logEvent(eventName, {
      localId: getLocalId(),
      ...params
    })

    Sentry.addBreadcrumb({
      category: 'log_event',
      message: eventName,
      level: 'info'
    })

    if (!__DEV__ && THIRD_PARTY_SUPPORTED_EVENTS.includes(eventName)) {
      const firebaseService = await firebaseServicePromise

      try {
        logEventToThirdParty(firebaseService, facebookService, gTagService, twitterService, eventName, params) // Pinterest service is removed temporarily.
      } catch (error) {
        console.error(error)
        // firebaseApp.crashlytics().recordError(error)
      }
    }
  }
}
