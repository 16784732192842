import { AppContextState, useAppState } from 'AppContextProvider'
import { AnalyticEvents, DefaultConfig } from 'Lib/Constants'
import { formatTimeLeft } from 'Lib/UIUtils'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { getSearchParams } from './AppUtils'
import { BadgePackageTagId, PackageOrderStyle, PackageTagId, PaymentColorScheme, PromotionLabelStyle } from 'types/onboardingTypes'
import { useFeatureValue } from '@growthbook/growthbook-react'

export const useDiscountTimer = (shouldRemoveDiscount: boolean) => {
  const [discountTimeLeft, setDiscountTimeLeft] = useState<string | null>(null)
  const { appState, setAppState } = useAppState()

  const searchParams = getSearchParams()
  const localStorageDiscountTimer = localStorage.getItem('dc')
  const searchParamsDiscountTimer = searchParams.get('dc')
  const withDiscountTimer = searchParamsDiscountTimer === 'true' || localStorageDiscountTimer === 'true' || DefaultConfig.WITH_DISCOUNT_TIMEOUT

  let countdownInterval: NodeJS.Timeout
  useEffect(() => {
    if (appState.stripePrices) {
      const discountAvailable = appState.stripePrices.some((pkg) => !!pkg.discountedPrice)

      if (!discountAvailable) {
        setDiscountTimeLeft('')
        return
      }

      if (!withDiscountTimer) {
        return
      }

      let countdown = localStorage.getItem('dscExp')
      if (!countdown) {
        countdown = (Math.floor(Date.now()) + DefaultConfig.DISCOUNT_COUNTDOWN_DURATION).toString()
        localStorage.setItem('dscExp', countdown)
        setAppState({ ...appState, discountAvailable: true })
      }

      countdownInterval = setInterval(() => {
        const expirationTimestamp = localStorage.getItem('dscExp')
        const currentTime = Math.floor(Date.now())
        const remainingTime = Math.floor(parseInt(expirationTimestamp!) - currentTime)
        if (remainingTime <= 0) {
          // format stripe packages without the discounts
          if (shouldRemoveDiscount) {
            const newAppContextState: AppContextState = {
              ...appState,
              discountAvailable: false,
              stripePrices: appState.stripePrices!.map((pkg) => (
                {
                  ...pkg,
                  discountedPrice: undefined,
                  discountedPricePerDay: undefined
                }))
            }
            setAppState(newAppContextState)
          }
          setDiscountTimeLeft('')
          clearInterval(countdownInterval)
        } else {
          setDiscountTimeLeft(formatTimeLeft(Math.floor(remainingTime / 1000)))
        }
      }, 1000)
    }
    return () => {
      clearInterval(countdownInterval)
    }
  }, [appState.stripePrices, shouldRemoveDiscount])

  return { withDiscountTimer, discountTimeLeft }
}

// export const useRemoteConfig = (key: string): (RemoteConfigValue | null) => {
//   const [value, setValue] = useState<RemoteConfigValue | null>(null)
//   const [loading, setLoading] = useState(true)
//   useEffect(() => {
//     const applyRemoteConfig = async () => {
//       try {
//         const firebaseApp: IFirebaseService = await firebaseServicePromise
//         const remoteConfigValue = firebaseApp.getRemoteConfigValue(key)
//         setValue(remoteConfigValue)
//         setLoading(false)
//       } catch (error) {
//         logEvent('remoteConfigError_fetchFailed')
//         setLoading(false)
//       }
//     }
//     void applyRemoteConfig()
//   }, [key])

//   return value
// }

// export const useRemoteConfigBoolean = (key: string): boolean | null => {
//   const boolValue = useRemoteConfig(key)
//   const remoteConfigValue = boolValue != null ? boolValue.asBoolean() : null

//   return remoteConfigValue
// }

// export const useRemoteConfigString = (key: string): string | null => {
//   const stringValue = useRemoteConfig(key)
//   const remoteConfigValue = stringValue != null ? stringValue.asString() : null

//   return remoteConfigValue
// }

/**
 * uses browser URLSearchParams API (not supported in IE11) to parse url query
 * */
export function useQuery() {
  return new URLSearchParams(useLocation().search)
}

// GrowthBook feature flags
export function usePaymentScreenColorScheme(): PaymentColorScheme {
  const colorScheme = useFeatureValue('payment-screen-color-palette', 'original')

  if (['blue', 'classic', 'green', 'orange', 'original'].includes(colorScheme)) {
    return colorScheme as PaymentColorScheme
  } else {
    logError(AnalyticEvents.WEB_GROWTHBOOK_WRONG_FEATURE, new Error('unexpected color scheme'), { colorScheme }, true)
    return 'original'
  }
}

export function usePaymentPromotionLabelStyle(): PromotionLabelStyle {
  const promotionLabelStyle = useFeatureValue('payment-screen-promotion-label-style', 'original')

  if (['original', 'mostPopularFastTrack'].includes(promotionLabelStyle)) {
    return promotionLabelStyle as PromotionLabelStyle
  } else {
    logError(AnalyticEvents.WEB_GROWTHBOOK_WRONG_FEATURE, new Error('unexpected promotionLabelStyle'), { promotionLabelStyle }, true)
    return 'original'
  }
}

export function useStripePackageOrder(): PackageOrderStyle {
  const fallbackValue: PackageOrderStyle = 'original' as PackageOrderStyle
  const packageOrderStyle = useFeatureValue('payment-screen-package-order-style', fallbackValue)

  if (['original', 'reversed'].includes(packageOrderStyle)) {
    return packageOrderStyle as PackageOrderStyle
  }
  logError(AnalyticEvents.WEB_GROWTHBOOK_WRONG_FEATURE, new Error('unexpected package order style'), { packageOrderStyle }, true)
  return fallbackValue
}

export function useBadgePackageTagId(): BadgePackageTagId {
  const badgePackageTagId = useFeatureValue('payment-screen-badge-package-tag-id', BadgePackageTagId.None) as BadgePackageTagId

  if (!Object.values(BadgePackageTagId).includes(badgePackageTagId)) {
    logError(AnalyticEvents.WEB_GROWTHBOOK_WRONG_FEATURE, new Error('unexpected badgePackageTagId'), { badgePackageTagId }, true)
    return BadgePackageTagId.None
  }
  return badgePackageTagId
}
