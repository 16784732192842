import PaymentWrapper from 'Components/Payment/PaymentWrapper'
import PaymentWrapperWithModal from 'Components/Payment/PaymentWrapperWithModal'
import { AnalyticEvents } from 'Lib/Constants'
import React, { useEffect } from 'react'
import { stripeService } from 'store'

import { useFeatureValue } from '@growthbook/growthbook-react'
import { Elements } from '@stripe/react-stripe-js'

const PaymentContainer = () => {
  const paymentFormModalOrInline = useFeatureValue('payment-form-improvement', 'modal')

  useEffect(() => {
    logEvent(AnalyticEvents.PAYMENT_MOUNTED)
  }, [])

  return (
    <Elements stripe={stripeService}>
      {paymentFormModalOrInline === 'modal' ? <PaymentWrapperWithModal /> : <PaymentWrapper />}
    </Elements>
  )
}

export default PaymentContainer
