interface DefaultConfigParams {
  SUBSCRIPTION_GROUP: SubscriptionGroupId
  DISCOUNT_COUNTDOWN_DURATION: number
  WITH_DISCOUNT_TIMEOUT: boolean
  WITH_DISCOUNT_COOLDOWN_RESET: boolean
  DISCOUNT_COOLDOWN_RESET_TIMEOUT: number
}

// __DEV__ flag might not be available at root level. the check is made directly
const isDev = location.hostname !== 'my.bodyfast.app'

export const DefaultConfig: DefaultConfigParams = {
  SUBSCRIPTION_GROUP: 'v8',
  DISCOUNT_COUNTDOWN_DURATION: isDev ? 60 * 1000 : 600 * 1000, // 10 min in ms for prod. 60s for test
  WITH_DISCOUNT_TIMEOUT: false,
  WITH_DISCOUNT_COOLDOWN_RESET: true,
  DISCOUNT_COOLDOWN_RESET_TIMEOUT: isDev ? 2 * 60 * 1000 : 10 * 60 * 1000 // 2 minutes for dev, 10 min for prod
}

export const PAYPAL_SUPPORTED_CURRENCIES = ['AUD', 'BRL', 'CAD', 'CHF', 'CZK', 'DKK', 'EUR', 'GBP', 'HKD', 'HUF', 'ILS', 'JPY', 'MXN', 'NOK', 'NZD', 'PHP', 'PLN', 'RUB', 'SEK', 'SGD', 'THB', 'TWD', 'USD']

export const TRANSITION_ANIMATION_DURATION = 500
export const NON_ANIMATING_STEP_TYPES: { [stepType: string]: boolean } = {
  custom: true,
  information: true,
  email: true
}

export enum AnalyticEvents {
  SIGN_UP_SUCCESS = 'web_signUpSuccess',
  SIGN_UP_FAIL = 'web_signUpFail',
  SIGN_IN_SUCCESS = 'web_signInSuccess',
  SIGN_IN_FAIL = 'web_signInFail',
  ANONYMOUS_SIGN_UP_FAIL = 'web_anonymousSignUpFailed',
  ONBOARDING_DATA_SAVE_SUCCESS = 'web_onboardingDataSaveSuccess',
  ONBOARDING_DATA_SAVE_FAIL = 'web_onboardingDataSaveFail',
  LOGOUT = 'web_logout',
  LOGOUT_FAIL = 'web_logoutFail',
  CREATE_NEW_USER = 'web_createNewUser',
  FETCH_PRICES_FAIL = 'web_fetchPricesFail',
  SIGN_UP_WITH_GOOGLE_REQUEST = 'web_signUpWithGoogle_request',
  SIGN_UP_WITH_APPLE_REQUEST = 'web_signUpWithApple_request',
  SIGN_IN_WITH_GOOGLE_REQUEST = 'web_signInWithGoogle_request',
  SIGN_IN_WITH_GOOGLE_SUCCESS = 'web_signInWithGoogle_success',
  SIGN_IN_WITH_GOOGLE_FAIL = 'web_signInWithGoogle_fail',
  REGISTER_WITH_GOOGLE_REQUEST = 'web_registerWithGoogle_request',
  REGISTER_WITH_GOOGLE_SUCCESS = 'web_registerWithGoogle_success',
  REGISTER_WITH_GOOGLE_FAIL = 'web_registerWithGoogle_fail',
  SIGN_IN_WITH_GOOGLE_CLOSED_BY_USER = 'web_signInWithGoogle_closedByUser',
  UPGRADE_USER_WITH_GOOGLE_REQUEST = 'web_upgradeUserWithGoogle_request',
  UPGRADE_USER_WITH_GOOGLE_SUCCESS = 'web_upgradeUserWithGoogle_success',
  UPGRADE_USER_WITH_GOOGLE_FAIL = 'web_upgradeUserWithGoogle_fail',
  SIGN_IN_WITH_APPLE_REQUEST = 'web_signInWithApple_request',
  SIGN_IN_WITH_APPLE_SUCCESS = 'web_signInWithApple_success',
  REGISTER_WITH_APPLE_REQUEST = 'web_registerWithApple_request',
  REGISTER_WITH_APPLE_SUCCESS = 'web_registerWithApple_success',
  REGISTER_WITH_APPLE_FAIL = 'web_registerWithApple_fail',
  UPGRADE_WITH_APPLE_REQUEST = 'web_upgradeWithApple_request',
  UPGRADE_WITH_APPLE_SUCCESS = 'web_upgradeWithApple_success',
  UPGRADE_WITH_APPLE_FAIL = 'web_upgradeWithApple_fail',
  SIGN_IN_WITH_APPLE_FAIL = 'web_signInWithApple_fail',
  SIGN_IN_WITH_APPLE_CLOSED_BY_USER = 'web_signInWithApple_closedByUser',
  SIGN_IN_WITH_GOOGLE_ONE_TAP = 'web_signInWithGoogleOneTap',
  SIGN_IN_WITH_GOOGLE_ONE_TAP_FAIL = 'web_signInWithGoogleOneTap_fail',
  SIGN_IN_WITH_EMAIL_SUCCESS = 'web_signInWithEmail_success',
  SIGN_IN_WITH_EMAIL_FAIL = 'web_signInWithEmail_fail',
  USER_ALREADY_LOGGED_IN = 'web_userAlreadyLoggedIn',
  ONBOARDING_COMPLETED = 'web_onboarding_completed',
  OPEN_PAYMENT_MODAL = 'web_openPaymentModal',
  CLOSE_PAYMENT_MODAL = 'web_closePaymentModal',
  START_CHECKOUT = 'web_startCheckout',
  STRIPE_LOAD_SCRIPT_FAIL = 'web_stripeLoadScriptFail',
  STRIPE_SESSION_CREATION_EXCEPTION = 'web_stripeSessionCreationException',
  STRIPE_SESSION_CREATION_SUCCESS = 'web_stripeSessionCreationSuccess',
  WEB_PURCHASE_EXCEPTION = 'web_purchaseException',
  PURCHASE_SUCCESS = 'web_purchaseSuccess',
  RESOLVE_PURCHASE_NO_STRIPE_SUBSCRIPTION = 'web_purchase_noStripeSubscription',
  RESOLVE_PURCHASE_NO_PAYPAL_SUBSCRIPTION = 'web_purchase_noPaypalSubscription',
  RESOLVE_PURCHASE_SYNC_FAILED = 'web_purchase_syncFailed',
  CLEAR_APP_DATA_STARTED = 'web_clearAppDataStart',
  CLEAR_APP_DATA_FINISHED = 'web_clearAppDataFinished',
  WEB_DEV_SIGN_IN_AND_SAVE_FAIL = 'web_dev_signInAndSaveFail',
  SIGN_IN_WITH_TOKEN_REQUEST = 'web_token_sign_in_request',
  SIGN_IN_WITH_TOKEN_FAIL = 'web_token_sign_in_fail',
  SIGN_IN_WITH_TOKEN_SUCCESS = 'web_token_sign_in_success',
  WEB_OPEN_EMAIL_EXISTS_MODAL = 'web_openEmailExistsModal',
  WEB_CANCEL_EMAIL_EXISTS_MODAL = 'web_cancelEmailExistsModal',
  WEB_CONFIRM_EMAIL_EXISTS_MODAL = 'web_confirmEmailExistsModal',
  WEB_OPEN_USER_NOT_FOUND_MODAL = 'web_openUserNotFoundModal',
  WEB_CLOSE_USER_NOT_FOUND_MODAL = 'web_closeUserNotFoundModal',
  WEB_OPEN_RESET_PASSWORD_MODAL = 'web_openResetPasswordModal',
  WEB_CLOSE_RESET_PASSWORD_MODAL = 'web_closeResetPasswordModal',
  USER_NOT_LOGGED_IN = 'web_userNotLoggedIn',
  FETCH_STRIPE_PRICES_ERROR = 'web_fetchStripePricesError',
  NAVIGATE_TO_POST_ONBOARDING_ROUTES_FAIL = 'web_navigateToPostOnboardingRoutesFail',
  WEB_FUTOKEN_INVALID = 'web_futoken_invalid',
  WEB_FUTOKEN_ERROR = 'web_futoken_error',
  WEB_STRIPE_ELEMENTS_REFRESHED = 'web_stripeElementsRefreshed',
  WEB_SIGNIN_BUTTON_PRESSED = 'web_signin_button_pressed',
  WEB_SIGNUP_BUTTON_PRESSED = 'web_signup_button_pressed',
  WEB_REGISTER_WITH_EMAIL_AND_PASSWORD_REQUEST = 'web_registerWithEmailAndPass_request',
  WEB_REGISTER_WITH_EMAIL_AND_PASSWORD_SUCCESS = 'web_registerWithEmailAndPass_success',
  WEB_REGISTER_WITH_EMAIL_AND_PASSWORD_FAIL = 'web_registerWithEmailAndPass_fail',
  WEB_PINTEREST_LOGGING_ERROR = 'web_pinterest_logging_error',
  WEB_PINTEREST_NOT_INITIALIZED = 'web_pinterest_not_initialized',
  WEB_REGISTRATION_MOUNTED_WITHOUT_USER = 'web_registrationMountedWithoutUser',
  WEB_STRIPE_PURCHASE_NOT_AVAILABLE = 'web_stripePurchaseNotAvailable',
  STARTUP_TIMEOUT = 'web_startupTimeout',
  AUTH_INITIALIZED = 'web_authInitialized',
  ERROR_UNAUTHENTICATED_ROUTE = 'web_error_unauthenticatedRoute',
  TOKEN_EXISTS_WITHOUT_USER = 'web_tokenExistsWithoutUser',
  REMOTE_CONFIG_INITIALIZATION_ERROR = 'web_remoteConfigInitializationError',
  STRIPE_NOT_LOADED = 'web_stripe_not_loaded',
  STRIPE_PAYMENT_ERROR = 'web_stripe_paymentError',
  STRIPE_PAYMENT_LOAD_ERROR = 'web_stripe_paymentLoadError',
  START_UP_SUCCESS = 'web_startUpSuccess',
  WEB_NO_PARAMS_FOUND = 'web_no_params_found',
  WEB_COOKIE_CONSENT_UPDATE = 'web_cookie_consent_update',
  WEB_COOKIE_CONSENT_GRANTED = 'web_cookie_consent_granted',
  I18N_ERROR = 'web_i18n_error',
  WEB_TWITTER_SERVICE_NOT_INITIALIZED = 'web_twitter_service_not_initialized',
  WEB_APPSFLYER_SERVICE_NOT_INITIALIZED = 'web_appsflyer_service_not_initialized',
  WEB_FETCH_MARKDOWN_ERROR = 'web_fetch_markdown_error',
  WEB_GROWTHBOOK_WRONG_FEATURE = 'web_growthBookWrongFeature',
  WEB_SELECTED_TAG_CONFIG_ERROR = 'web_selectedTagConfigError',
  WEB_GENERATE_CUSTOM_TOKEN_ERROR = 'web_generateCustomTokenError',
  WEB_GENERATE_CUSTOM_TOKEN_SUCCESS = 'web_generateCustomTokenSuccess',
  WEB_CLICKED_DOWNLOAD_APP = 'web_clickedDownloadApp',
  WEB_UPSELL_SKIPPED = 'web_upsellSkipped',
  FIREBASE_INITIALIZED = 'web_firebaseInitialized',
  PAYMENT_MOUNTED = 'web_paymentMounted',
}

export const GO_TO_ONBOARDING_EVENTS_MAP: { [key: string]: string } = {
  '': 'web_goto_0_initial',
  onboarding: 'web_goto_0_onboarding',
  loading: 'web_goto_0_loading',
  gender: 'web_goto_1_gender',
  tracked_hours: 'web_goto_2_tracked_hours',
  goals: 'web_goto_3_goals',
  age_range: 'web_goto_3_age_range',
  desired_body_type: 'web_goto_4_desired_body_type',
  current_body_type: 'web_goto_5_current_body_type',
  target_zones: 'web_goto_6_target_zones',
  last_happy_weight: 'web_goto_7_last_happy_weight',
  breakfast_time: 'web_goto_8_breakfast_time',
  lunch_time: 'web_goto_9_lunch_time',
  dinner_time: 'web_goto_10_dinner_time',
  home_or_outside: 'web_goto_11_home_or_outside',
  reinforcement_scientific: 'web_goto_12_info-scientific',
  fasting_feelings: 'web_goto_13_fasting_feelings',
  workload_frequency: 'web_goto_14_workload_frequency',
  work_activity_level: 'web_goto_15_work_activity_level',
  out_of_breath: 'web_goto_16_out_of_breath',
  walking_time: 'web_goto_17_walking_time',
  water_intake: 'web_goto_18_water_intake',
  sleep_quality: 'web_goto_19_sleep_quality',
  bad_habits: 'web_goto_20_bad_habits',
  reinforcement_before_after: 'web_goto_21_info-before-after',
  fasting_knowledge: 'web_goto_22_fasting_knowledge',
  fasting_motivation: 'web_goto_23_fasting_motivation',
  height: 'web_goto_24_height',
  weight: 'web_goto_25_weight',
  perfect_weight: 'web_goto_26_perfect_weight',
  age: 'web_goto_27_age',
  bmi: 'web_goto_28_bmi',
  special_occasion: 'web_goto_29_special-occasion',
  special_occasion_date: 'web_goto_30_special-occasion-date',
  the_one_plan: 'web_goto_31_the-one-plan',
  progress: 'web_goto_32_progress_step',
  email: 'web_goto_33_email',
  existing_email_login: 'web_goto_34_loginExistingUser',
  authenticateUser: 'web_goto_34_authenticateUser',
  receive_email: 'web_goto_34_receive-email',
  plan_is_ready: 'web_goto_35_plan-is-ready',
  payment: 'web_goto_36_payment',
  payment_success: 'web_goto_36_payment-success',
  upgrade_account: 'web_goto_37_upgrade-account',
  download: 'web_goto_38_download'
}

export const COMMIT_ONBOARDING_EVENTS_MAP: { [key: string]: string } = {
  '': 'web_commit_0_initial',
  onboarding: 'web_commit_0_onboarding',
  loading: 'web_commit_0_loading',
  gender: 'web_commit_1_gender',
  tracked_hours: 'web_commit_2_tracked_hours',
  goals: 'web_commit_3_goals',
  age_range: 'web_commit_3_age_range',
  desired_body_type: 'web_commit_4_desired_body_type',
  current_body_type: 'web_commit_5_current_body_type',
  target_zones: 'web_commit_6_target_zones',
  last_happy_weight: 'web_commit_7_last_happy_weight',
  breakfast_time: 'web_commit_8_breakfast_time',
  lunch_time: 'web_commit_9_lunch_time',
  dinner_time: 'web_commit_10_dinner_time',
  home_or_outside: 'web_commit_11_home_or_outside',
  reinforcement_scientific: 'web_commit_12_info-scientific',
  fasting_feelings: 'web_commit_13_fasting_feelings',
  workload_frequency: 'web_commit_14_workload_frequency',
  work_activity_level: 'web_commit_15_work_activity_level',
  out_of_breath: 'web_commit_16_out_of_breath',
  walking_time: 'web_commit_17_walking_time',
  water_intake: 'web_commit_18_water_intake',
  sleep_quality: 'web_commit_19_sleep_quality',
  bad_habits: 'web_commit_20_bad_habits',
  reinforcement_before_after: 'web_commit_21_info-before-after',
  fasting_knowledge: 'web_commit_22_fasting_knowledge',
  fasting_motivation: 'web_commit_23_fasting_motivation',
  height: 'web_commit_24_height',
  weight: 'web_commit_25_weight',
  perfect_weight: 'web_commit_26_perfect_weight',
  age: 'web_commit_27_age',
  bmi: 'web_commit_28_bmi',
  special_occasion: 'web_commit_29_special-occasion',
  special_occasion_date: 'web_commit_30_special-occasion-date',
  the_one_plan: 'web_commit_31_the-one-plan',
  progress: 'web_commit_32_progress_step',
  email: 'web_commit_33_email',
  existing_email_login: 'web_commit_34_loginExistingUser',
  authenticateUser: 'web_commit_34_authenticateUser',
  receive_email: 'web_commit_34_receive-email',
  plan_is_ready: 'web_commit_35_plan-is-ready',
  payment: 'web_commit_36_payment',
  payment_success: 'web_commit_36_payment-success',
  upgrade_account: 'web_commit_37_upgrade-account',
  download: 'web_commit_38_download'
}

export const GO_BACK_TO_ONBOARDING_EVENTS_MAP: { [key: string]: string } = {
  '': 'web_go_back_to_0_initial',
  onboarding: 'web_go_back_to_0_onboarding',
  loading: 'web_go_back_to_0_loading',
  gender: 'web_go_back_to_1_gender',
  tracked_hours: 'web_go_back_to_2_tracked_hours',
  goals: 'web_go_back_to_3_goals',
  age_range: 'web_go_back_to_3_age_range',
  desired_body_type: 'web_go_back_to_4_desired_body_type',
  current_body_type: 'web_go_back_to_5_current_body_type',
  target_zones: 'web_go_back_to_6_target_zones',
  last_happy_weight: 'web_go_back_to_7_last_happy_weight',
  breakfast_time: 'web_go_back_to_8_breakfast_time',
  lunch_time: 'web_go_back_to_9_lunch_time',
  dinner_time: 'web_go_back_to_10_dinner_time',
  home_or_outside: 'web_go_back_to_11_home_or_outside',
  reinforcement_scientific: 'web_go_back_to_12_info-scientific',
  fasting_feelings: 'web_go_back_to_13_fasting_feelings',
  workload_frequency: 'web_go_back_to_14_workload_frequency',
  work_activity_level: 'web_go_back_to_15_work_activity_level',
  out_of_breath: 'web_go_back_to_16_out_of_breath',
  walking_time: 'web_go_back_to_17_walking_time',
  water_intake: 'web_go_back_to_18_water_intake',
  sleep_quality: 'web_go_back_to_19_sleep_quality',
  bad_habits: 'web_go_back_to_20_bad_habits',
  reinforcement_before_after: 'web_go_back_to_21_info-before-after',
  fasting_knowledge: 'web_go_back_to_22_fasting_knowledge',
  fasting_motivation: 'web_go_back_to_23_fasting_motivation',
  height: 'web_go_back_to_24_height',
  weight: 'web_go_back_to_25_weight',
  perfect_weight: 'web_go_back_to_26_perfect_weight',
  age: 'web_go_back_to_27_age',
  bmi: 'web_go_back_to_28_bmi',
  special_occasion: 'web_go_back_to_29_special-occasion',
  special_occasion_date: 'web_go_back_to_30_special-occasion-date',
  the_one_plan: 'web_go_back_to_31_the-one-plan',
  progress: 'web_go_back_to_32_progress_step',
  email: 'web_go_back_to_33_email',
  existing_email_login: 'web_go_back_to_34_loginExistingUser',
  authenticateUser: 'web_go_back_to_34_authenticateUser',
  receive_email: 'web_go_back_to_34_receive-email',
  plan_is_ready: 'web_go_back_to_35_plan-is-ready',
  payment: 'web_go_back_to_36_payment',
  payment_success: 'web_go_back_to_36_payment-success',
  upgrade_account: 'web_go_back_to_37_upgrade-account',
  download: 'web_go_back_to_38_download'
}

export const SKIP_ONBOARDING_EVENTS_MAP: { [key: string]: string } = {
  '': 'web_skip_0_initial',
  onboarding: 'web_skip_0_onboarding',
  loading: 'web_skip_0_loading',
  gender: 'web_skip_1_gender',
  tracked_hours: 'web_skip_2_tracked_hours',
  goals: 'web_skip_3_goals',
  age_range: 'web_skip_3_age_range',
  desired_body_type: 'web_skip_4_desired_body_type',
  current_body_type: 'web_skip_5_current_body_type',
  target_zones: 'web_skip_6_target_zones',
  last_happy_weight: 'web_skip_7_last_happy_weight',
  breakfast_time: 'web_skip_8_breakfast_time',
  lunch_time: 'web_skip_9_lunch_time',
  dinner_time: 'web_skip_10_dinner_time',
  home_or_outside: 'web_skip_11_home_or_outside',
  reinforcement_scientific: 'web_skip_12_info-scientific',
  fasting_feelings: 'web_skip_13_fasting_feelings',
  workload_frequency: 'web_skip_14_workload_frequency',
  work_activity_level: 'web_skip_15_work_activity_level',
  out_of_breath: 'web_skip_16_out_of_breath',
  walking_time: 'web_skip_17_walking_time',
  water_intake: 'web_skip_18_water_intake',
  sleep_quality: 'web_skip_19_sleep_quality',
  bad_habits: 'web_skip_20_bad_habits',
  reinforcement_before_after: 'web_skip_21_info-before-after',
  fasting_knowledge: 'web_skip_22_fasting_knowledge',
  fasting_motivation: 'web_skip_23_fasting_motivation',
  height: 'web_skip_24_height',
  weight: 'web_skip_25_weight',
  perfect_weight: 'web_skip_26_perfect_weight',
  age: 'web_skip_27_age',
  bmi: 'web_skip_28_bmi',
  special_occasion: 'web_skip_29_special-occasion',
  special_occasion_date: 'web_skip_30_special-occasion-date',
  the_one_plan: 'web_skip_31_the-one-plan',
  progress: 'web_skip_32_progress_step',
  email: 'web_skip_33_email',
  existing_email_login: 'web_skip_34_loginExistingUser',
  authenticateUser: 'web_skip_34_authenticateUser',
  receive_email: 'web_skip_34_receive-email',
  plan_is_ready: 'web_skip_35_plan-is-ready',
  payment: 'web_skip_36_payment',
  payment_success: 'web_skip_36_payment-success',
  upgrade_account: 'web_skip_37_upgrade-account',
  download: 'web_skip_38_download'
}

export const GO_TO_LOGIN_EVENTS_MAP: { [key: string]: string } = {
  login: 'web_l_goto_1_login',
  create_account: 'web_l_goto_1_create-account',
  token_login: 'web_l_goto_1_token',
  payment: 'web_l_goto_2_payment',
  'payment-success': 'web_l_goto_2_payment-success',
  upgrade_account: 'web_l_goto_3_upgrade-account',
  download: 'web_l_goto_4_download'
}

export const COMMIT_LOGIN_EVENTS_MAP: { [key: string]: string } = {
  login: 'web_l_commit_1_login',
  create_account: 'web_l_commit_1_create-account',
  token_login: 'web_l_commit_1_token',
  payment: 'web_l_commit_2_payment',
  'payment-success': 'web_l_commit_2_payment-success',
  upgrade_account: 'web_l_commit_3_upgrade-account',
  download: 'web_l_commit_4_download'
}

export const GO_BACK_TO_LOGIN_EVENTS_MAP: { [key: string]: string } = {
  login: 'web_l_go_back_to_1_login',
  create_account: 'web_l_go_back_to_1_create-account',
  token_login: 'web_l_go_back_to_1_token',
  payment: 'web_l_go_back_to_2_payment',
  'payment-success': 'web_l_go_back_to_2_payment-success',
  upgrade_account: 'web_l_go_back_to_3_upgrade-account',
  download: 'web_l_go_back_to_4_download'
}

export const STATIC_ROUTE_MAP: { [key: string]: string } = {
  privacy: 'web_static_privacy',
  cookie_policy: 'web_static_cookie-policy',
  terms: 'web_static_terms'
}

export const ZERO_DECIMAL_CURRENCIES = ['BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF']
