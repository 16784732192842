import { useAppState } from 'AppContextProvider'
import Benefits from 'Components/Payment/Benefits'
import DiscountBanner from 'Components/Payment/DiscountBanner'
import MoneyBackGuarantee from 'Components/Payment/MoneyBackGuarantee'
import PaymentHeader from 'Components/Payment/PaymentHeader'
import Summary from 'Components/Payment/Summary'
import { Title } from 'Components/Payment/Title'
import UserOurApp from 'Components/Payment/UserOurApp'
import {
  getDiscountPercentage, getPaymentScreenColorPackage, getSearchParams, navigateToPaymentSuccess,
  useDiscountTimer
} from 'Lib'
import { AnalyticEvents } from 'Lib/Constants'
import { usePaymentScreenColorScheme } from 'Lib/hooks'
import { convertKgToLbs, round0 } from 'Lib/SelectionsUtils'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Selectors } from 'Reducers'
import { firebaseServicePromise } from 'store'
import styled from 'styled-components'

import { useFeatureValue } from '@growthbook/growthbook-react'
import * as Sentry from '@sentry/react'
import { useStripe } from '@stripe/react-stripe-js'
import { StripeError } from '@stripe/stripe-js'

import BuyPackage from './BuyPackageModal/BuyPackage'
import Packages from './Packages'
import { Subtitle } from './Subtitle'

const SCROLL_DELAY = 650

const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: auto;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: ${props => props.theme.maxWidth};
  margin: auto;
  width: 100%;
  margin-top: 80px;
  margin-bottom: 20px;
`

const StepArea = styled.div`
  padding: ${props => props.theme.spacing.small};
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  box-sizing: border-box;         /* Opera/IE 8+ */
  width: 100%;
  position: relative;
  display: flex;
`

const Spacer = styled.div`
  height: ${props => props.theme.spacing.medium};
`

const PaymentMethodForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => props.theme.spacing.xLarge};
`

const PaymentSubtitle = styled(Subtitle)`
  margin-top: ${props => props.theme.spacing.medium};
  margin-bottom: ${props => props.theme.spacing.large};
`

const PaymentWrapper = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const stripe = useStripe()

  const buyPackageRef = useRef<HTMLDivElement>(null)

  const { appState, initStorage } = useAppState()
  const user = useSelector(Selectors.getUser)
  const personalData = user?.personal

  const [selectedPackageId, setSelectedPackageId] = useState<string | null>(null)
  const [hasLoadedPackage, setHasLoadedPackage] = useState<boolean>(false)
  const [atTop, setAtTop] = useState(true)
  const { discountTimeLeft, withDiscountTimer } = useDiscountTimer(false)
  const [isLoading, setIsLoading] = useState(true)
  const [externalStripeError, setExternalStripeError] = useState<StripeError>()

  const goalWeight = personalData?.goalWeight
    ? personalData?.unitSystem === 'cm/kg'
      ? round0(personalData?.goalWeight)
      : round0(convertKgToLbs(personalData?.goalWeight))
    : undefined

  useEffect(() => {
    if (!stripe) {
      return
    }
    /*
      Stripe elements just returns as part of the URL the status of the payment intent (succeeded | rejected)

      There is NO successUrl or rejectUrl. The user needs to be sent back to the payment page and then redirected
      by the client depending on the status of his payment (e.g. for Paypal Mobile that loads in a completely different page)
     */
    const handleStripeElementsExternalPagePaymentResult = async () => {
      setIsLoading(true)

      const searchParams = getSearchParams()

      const clientSecret = searchParams.get('payment_intent_client_secret')
      const subscriptionId = searchParams.get('subscriptionId')
      const selectedPackageId = searchParams.get('selectedPackageId')

      /*
        these params are injected on the URL -> subscriptionId by us. payment_intent_client_secret by stripe.
        when the user gets returned from an external page such as paypal mobile, we need to fetch his payment intent again
        to make sure it was successful and redirect him to the payment_sucess page
      */
      if (!clientSecret || !subscriptionId) {
        setIsLoading(false)
        return
      }

      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)

      if (paymentIntent?.status === 'succeeded' && subscriptionId) {
        const firebase = await firebaseServicePromise
        const userTokenId = await firebase.getIdToken()
        await navigateToPaymentSuccess(history, userTokenId, subscriptionId, 'elements')
        setIsLoading(false)
        return
      }

      if (selectedPackageId != null) {
        setIsLoading(false)
        if (paymentIntent?.last_payment_error) {
          setExternalStripeError(paymentIntent?.last_payment_error)
        }
        handlePackageChanged(selectedPackageId)
      }
    }

    void handleStripeElementsExternalPagePaymentResult()
  }, [stripe, window.location.search])

  useEffect(() => {
    const handleScroll = () => {
      const isAtTop = window.scrollY < 70
      setAtTop(isAtTop)
    }

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (!hasLoadedPackage) {
      void initStorage()
    }
  }, [hasLoadedPackage])

  const selectedPackageIndex = useFeatureValue('payment-screen-selected-package-index', 1)
  const colorScheme = usePaymentScreenColorScheme()

  const colorPackage = getPaymentScreenColorPackage(colorScheme)

  useEffect(() => {
    if (appState.stripePrices && !selectedPackageId) {
      if (selectedPackageIndex < appState.stripePrices.length) {
        setSelectedPackageId(appState.stripePrices[selectedPackageIndex].id)
      } else if (appState.stripePrices.length === 1) {
        setSelectedPackageId(appState.stripePrices[0].id)
      } else {
        setSelectedPackageId(appState.stripePrices[1].id)
      }
      setHasLoadedPackage(true)
    }
  }, [appState.stripePrices])

  const scrollToCheckout = () => {
    if (!stripe) {
      logEvent(`${AnalyticEvents.STRIPE_NOT_LOADED}`)
      Sentry.captureException(new Error('Stripe not loaded'))
    }

    setTimeout(() => {
      buyPackageRef.current?.scrollIntoView({ behavior: 'smooth' })
    }, SCROLL_DELAY)
  }

  const handlePackageChanged = (id: string) => {
    setSelectedPackageId(id)
    scrollToCheckout()
  }

  const selectedPackage = appState.stripePrices?.find(pkg => pkg.id === selectedPackageId)
  const discountPercentage = selectedPackage ? getDiscountPercentage(selectedPackage) : null

  if (isLoading) {
    return null
  }

  return (
    <>
      <Center>
        {((appState.stripePrices && appState.stripePrices?.length > 0)) &&
        <PaymentHeader discountTimeLeft={discountTimeLeft} selectedPackageId={selectedPackageId} onTap={scrollToCheckout} atTop={atTop} colorPackage={colorPackage} />}
        <StepArea>
          <Container>
            <Title>{t('paymentTitle')}</Title>
            {personalData?.goalWeight && personalData?.initialExperience && (
              <Summary
                targetWeight={goalWeight!}
                fastingLevel={personalData?.initialExperience}
                unit={personalData?.unitSystem === 'cm/kg' ? 'kg' : 'lbs'}
                colorPackage={colorPackage}
              />
            )}
            {!(personalData?.goalWeight && personalData?.initialExperience) && (
              <Spacer/>
            )}
            {(!!discountPercentage) &&
            <>
              <DiscountBanner
                colorPackage={colorPackage}
                discountTimeLeft={discountTimeLeft}
                withDiscountTimer={withDiscountTimer}
                discountPercentage={discountPercentage} />
              <Spacer/>
            </>
            }
            <Packages
              onPackageConfirm={scrollToCheckout}
              discountTimeLeft={discountTimeLeft}
              withDiscountTimer={withDiscountTimer}
              selectedPackageId={selectedPackageId}
              packages={appState.stripePrices}
              onPackageChanged={handlePackageChanged}
              colorPackage={colorPackage}

            />
            <Spacer />
            <UserOurApp />
            <Spacer />
            <Benefits isMale={personalData?.gender === 'm'} />
            <Title>{t('getResultsTitle')}</Title>
            {personalData?.goalWeight && personalData?.initialExperience && (
              <Summary
                targetWeight={goalWeight!}
                fastingLevel={personalData?.initialExperience}
                unit={personalData?.unitSystem === 'cm/kg' ? 'kg' : 'lbs'}
                colorPackage={colorPackage}
              />
            )}
            {!(personalData?.goalWeight && personalData?.initialExperience) && (
              <Spacer />
            )}
            <Packages
              onPackageConfirm={scrollToCheckout}
              discountTimeLeft={discountTimeLeft}
              withDiscountTimer={withDiscountTimer}
              selectedPackageId={selectedPackageId}
              packages={appState.stripePrices}
              onPackageChanged={handlePackageChanged}
              colorPackage={colorPackage}
            />
            <MoneyBackGuarantee />
            <Spacer/>
            <PaymentMethodForm ref={buyPackageRef}>
              <Title>{'Payment Method'}</Title>
              <PaymentSubtitle>{'We will use your payment details for seamless future payments'}</PaymentSubtitle>
              {selectedPackageId && <BuyPackage selectedPackageId={selectedPackageId}
                email={(user?.providerData.email ?? user?.newsletterEmail) as string}
                externalStripeError={externalStripeError}/>}
            </PaymentMethodForm>
          </Container>
        </StepArea>
      </Center>
    </>
  )
}

export default PaymentWrapper
